<template>
  <v-container fluid>
    <div class="home">
      <img
        alt="Vue logo"
        src="../assets/logo.png"
      >
      <HelloWorld msg="Welcome to Your Vue.js App" />
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>

<style lang="scss" scoped>
.home {
  text-align: center;
}
</style>
